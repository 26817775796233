<template>
    <div>
        <v-card class="text-center pa-1">
            <v-card-title class="justify-center display-1 mb-2">
                Autentificare
            </v-card-title>
            <v-card-subtitle>Monitorul Oficial Local</v-card-subtitle>

            <!-- sign in form -->
            <v-card-text>
                <v-form ref="form" v-model="isFormValid" lazy-validation>
                    <v-text-field
                        v-model="email"
                        :rules="[rules.required, rules.email]"
                        :validate-on-blur="false"
                        label="Email"
                        name="email"
                        :error-messages="errorMessages"
                        outlined
                        @keyup.enter="submit"
                        @change="resetErrors"
                    ></v-text-field>

                    <v-text-field
                        v-model="password"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required]"
                        :type="showPassword ? 'text' : 'password'"
                        :validate-on-blur="false"
                        label="Parola"
                        name="password"
                        outlined
                        :error-messages="errorMessages"
                        @change="resetErrors"
                        @keyup.enter="submit"
                        @click:append="showPassword = !showPassword"
                    ></v-text-field>

                    <v-btn
                        :loading="isLoading"
                        :disabled="isLoading"
                        block
                        x-large
                        color="primary"
                        @click="submit"
                        >Logare</v-btn
                    >
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: "SignIn",
    layout: "auth",
    auth: "guest",
    data() {
        return {
            isLoading: false,
            isFormValid: true,
            email: "",
            password: "",
            errorMessages: "",
            showPassword: false,
            rules: {
                required: value =>
                    (value && Boolean(value)) || "Câmp Obligatoriu",
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                    return pattern.test(value) || "E-mail invalid.";
                }
            }
        };
    },
    head() {
        return {
            title: "Autentificare"
        };
    },
    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                this.isLoading = true;
                this.signIn(this.email, this.password);
            }
        },
        async signIn(email, password) {
            try {
                await this.$auth.login({
                    data: {
                        email,
                        password
                    },
                    remember: email,
                    staySignedIn: true,
                    fetchUser: true
                });

                await this.$router.push("/");
            } catch (e) {
                if (e.response && e.response.data) {
                    this.errorMessages = e.response.data.errors.email[0];
                }
            }
            this.isLoading = false;
        },
        resetErrors() {
            this.errorMessages = "";
        }
    }
};
</script>
